import React from "react";
import { Footer, Navbar } from "../components";
const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Hakkımızda</h1>
        <hr />
        <p className="lead text-center">
          Tel: +90 546 236 33 30 <br />
          Tel: +90 532 201 31 81 • Fax: +90 0850 207 43 90 <br />
          Karacasu Ferhuş Mh. 80020 Sk. No: 6/A • Dulkadiroğlu / Kahramanmaraş{" "}
          <br />
          info@assosmetal.com
        </p>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
