import { Navbar, Main, Product, Footer } from "../components";
import assonseramik from "../assets/assos-soan1-800x600.jpg";
import assonnon from "../assets/assos-soaasdn-800x600.jpg";
import tek from "../assets/kapak.png";
import ozguven from "../assets/WhatsApp Image 2024-01-04 at 12.07.48.jpeg";
import { Link, NavLink } from "react-router-dom";
function Home() {
  return (
    <>
      <Navbar />
      <Main />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          margin: "20px",
        }}
      >
        <Link
          to={{
            pathname: "/product",
          }}
          state={{ filter: "seramik" }}
        >
          <img height={200} width={300} src={assonseramik} alt="" />
        </Link>
        <Link
          to={{
            pathname: "/product",
          }}
          state={{ filter: "non" }}
        >
          <img height={200} width={300} src={assonnon} alt="" />
        </Link>
        <Link
          to={{
            pathname: "/product",
          }}
          state={{ filter: "ozguven" }}
        >
          <img height={200} width={300} src={ozguven} alt="" />
        </Link>
        <Link
          to={{
            pathname: "/product",
          }}
          state={{ filter: "tek" }}
        >
          <img height={200} width={300} src={tek} alt="" />
        </Link>
      </div>
      <Footer />
    </>
  );
}

export default Home;
