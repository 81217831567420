import React from "react";

const Home = () => {
  return (
    <>
      <div className="hero border-1 pb-3">
        <div className="card bg-dark text-white border-0 mx-3">
          <img
            
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Zilina_zapalkaren.jpg/640px-Zilina_zapalkaren.jpg"
            alt="Card"
            height={400}
          />

        </div>
      </div>
    </>
  );
};

export default Home;
