import React from 'react'
import { Footer, Navbar, Product } from "../components"
import { useLocation } from 'react-router-dom'

const Products = () => {
  const location=useLocation()
  // console.log(location.state);
  return (
    <>
      <Navbar />
      <Product filtercode={location?.state?.filter} />
      <Footer />
    </>
  )
}

export default Products