import React from 'react'
import { Footer, Navbar } from "../components";
import image from '../assets/WhatsApp-Image-2024-01-03-at-14.20.49.jpeg'
const Prices = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Fiyatlarımız</h1>
        <hr />
        <img src={image} alt="" />

       
      </div>
      <Footer />
    </>
  )
}

export default Prices