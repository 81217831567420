import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Link, NavLink } from "react-router-dom";
import image1 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (1).jpeg";
import image2 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (10).jpeg";
import image3 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (16).jpeg";
import image4 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (12).jpeg";
import image5 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (13).jpeg";
import image6 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (14).jpeg";
import image7 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (15).jpeg";
import image9 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (4).jpeg";
import image8 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (18).jpeg";
import image10 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (6).jpeg";
import image11 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (2).jpeg";
import image12 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (21).jpeg";
import image13 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (22).jpeg";
import image14 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (23).jpeg";
import image15 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (8).jpeg";
import image16 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (11).jpeg";
import image17 from "../assets/WhatsApp Image 2024-01-03 at 23.37.46 (3).jpeg";
import assosser1 from "../assets/Assos Metal _ Seramik Ürünlerimiz_files/1s.png"
import assosser2 from "../assets/Assos Metal _ Seramik Ürünlerimiz_files/2s.png"
import assosser3 from "../assets/Assos Metal _ Seramik Ürünlerimiz_files/3s.png"
import assosser4 from "../assets/Assos Metal _ Seramik Ürünlerimiz_files/4s.png"
import assosnon1 from "../assets/Assos_nonstick/2.png"
import assosnon2 from "../assets/Assos_nonstick/4.png"
import assosnon3 from "../assets/Assos_nonstick/5.png"
import assosnon4 from "../assets/Assos_nonstick/6.png"
import assosnon5 from "../assets/Assos_nonstick/7.png"
import assosnon6 from "../assets/Assos_nonstick/8.png"
import assosnon7 from "../assets/Assos_nonstick/9.png"
import assosnon8 from "../assets/Assos_nonstick/10 (1).png"
import assosnon9 from "../assets/Assos_nonstick/12.png"
import assosnon10 from "../assets/Assos_nonstick/38.png"
import kapak1 from "../assets/Kapaklar/kapak1.png"
import kapak2 from "../assets/Kapaklar/kapak2.png"
import kapak3 from "../assets/Kapaklar/kapak3.png"


export const items = [
  {
    category:"tek",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id:32,
    image: kapak3,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Tek Kapaklar",
  },
  {
    category:"tek",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id:31,
    image: kapak2,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Tek Kapaklar",
  },
  {
    category:"tek",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id:30,
    image: kapak1,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Tek Kapaklar",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 29,
    image: assosnon8,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Kek Kalıbı",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 28,
    image: assosnon7,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Balık Tava",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 27,
    image: assosnon6,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Fırın tepsi",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 26,
    image: assosnon5,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Tek kulp Tava",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 25,
    image: assosnon4,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Çift kulp Tava",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 24,
    image: assosnon10,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Derin Tencere",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 23,
    image: assosnon1,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Derin Tencere",
  },
  {
    category:"non",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 22,
    image: assosnon9,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Basık Tencere",
  },
  {
    category:"seramik",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 21,
    image: assosser4,
    price: 9.95,
    rating: { rate: 4.3, count: 120 },
    title: "Seramik Dik Derin Tencere",
  },
  {
    category:"seramik",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 20,
    image: assosser3,
    price: 9.95,
    rating: { rate: 4.0, count: 120 },
    title: "Dik Seramik Basık Tencere",
  },
  {
    category:"seramik",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 19,
    image: assosser2,
    price: 9.95,
    rating: { rate: 4.7, count: 120 },
    title: "Basık Seramik Tencere",
  },
  {
    category:"seramik",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 18,
    image: assosser1,
    price: 9.95,
    rating: { rate: 4.7, count: 120 },
    title: "Derin Seramik Tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 1,
    image: image1,
    price: 9.95,
    rating: { rate: 4.7, count: 120 },
    title: "Teflon derin tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 2,
    image: image2,
    price: 4.95,
    rating: { rate: 4.3, count: 10 },
    title: "Teflon basık tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 3,
    image: image3,
    price: 109.95,
    rating: { rate: 3.5, count: 120 },
    title: "Teflon basık tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 4,
    image: image4,
    price: 109.95,
    rating: { rate: 4.3, count: 10 },
    title: "Teflon Tek kulp tava",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 5,
    image: image5,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Granit Tek kulp tava",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 6,
    image: image6,
    price: 109.95,
    rating: { rate: 4.5, count: 120 },
    title: "Tencere seti",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 7,
    image: image7,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Teflon Basık tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 8,
    image: image8,
    price: 109.95,
    rating: { rate: 4.0, count: 120 },
    title: "Granit basık tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 9,
    image: image9,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Granit Tek kulp tava",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 10,
    image: image10,
    price: 109.95,
    rating: { rate: 3.8, count: 120 },
    title: "Teflon Derin Tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 12,
    image: image12,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Teflon tek kulp tava",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 13,
    image: image13,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Granit basık tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 14,
    image: image14,
    price: 109.95,
    rating: { rate: 4.8, count: 120 },
    title: "Teflon basık tencere",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 15,
    image: image15,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Teflon Tencere seti",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 16,
    image: image16,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Teflon tek kulp tava",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 17,
    image: image17,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Teflon tek kulp tava",
  },
  {
    category:"ozguven",
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
    id: 17,
    image: image17,
    price: 109.95,
    rating: { rate: 3.9, count: 120 },
    title: "Teflon tek kulp tava",
  },
];

const Products = ({filtercode}) => {
  const [data, setData] = useState(items);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  let componentMounted = true;
  console.log("filtercode",filtercode);
  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await fetch("https://fakestoreapi.com/products/");
      if (componentMounted) {
        // setData(await response.clone().json());
        // setFilter(await response.json());
        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    getProducts();
    if(filtercode){
      setData(data.filter(item=>item.category==filtercode))
    }
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  };
  const ShowProducts = () => {
    // console.log(data);
    return (
      <>
       
        {data.map((product) => {
          return (
            <div
              id={product.id}
              key={product.id}
              className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4"
            >
              <div className="card text-center h-100" key={product.id}>
                <img
                  className="card-img-top p-3"
                  src={product.image}
                  alt="Card"
                  height={300}
                />
                <div className="card-body">
                  <h5 className="card-title">{product.title}</h5>
                </div>
                <div className="card-body">
                  <Link
                    to={"/product/" + product.id}
                    className="btn btn-dark m-1"
                  >
                    Göz at
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Yeni Ürünlerimiz</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
